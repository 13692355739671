import { FC } from "react";
import { Form, useLocation } from "@remix-run/react";

type RemoveFromCartFormProps = {
  children: React.ReactElement;
  itemId: number;
};

const RemoveFromCartForm: FC<RemoveFromCartFormProps> = ({ children, itemId }) => {
  let location = useLocation();
  return (
    <Form replace method="post" action="/cart" preventScrollReset={true}>
       <input
          type="hidden"
          name="_action"
          value="remove-from-cart"
        />
      <input
        key={location.pathname + location.search}
        defaultValue={location.pathname + location.search}
        type="hidden"
        name="redirect"
      />
      <input
        key={itemId}
        defaultValue={itemId}
        type="hidden"
        name="item-id"
      />
      {children}
    </Form>
  );
}

export default RemoveFromCartForm;