import { FC } from "react";
import { Form, useLocation } from "@remix-run/react";

type UpdateQtyCartFormProps = {
  children: React.ReactElement;
  itemId: number;
  quantity: number;
};

const UpdateQtyCartForm: FC<UpdateQtyCartFormProps> = ({ children, itemId, quantity }) => {
  let location = useLocation();
  return (
    <Form replace method="post" action="/cart">
       <input
          type="hidden"
          name="_action"
          value="set-quantity"
        />
      <input
        key={location.pathname + location.search}
        defaultValue={location.pathname + location.search}
        type="hidden"
        name="redirect"
      />
      <input
        key={itemId}
        defaultValue={itemId}
        type="hidden"
        name="item-id"
      />
      <input
        key={quantity}
        defaultValue={quantity}
        type="hidden"
        name="quantity"
      />
      {children}
    </Form>
  );
}

export default UpdateQtyCartForm;